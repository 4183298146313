<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="card-title w-100 d-flex justify-content-between">
        <h1>Şehirler </h1>
        <a v-if="false" href="javascript:void(0)"
           data-bs-toggle="modal"
           @click="city = {}"
           data-bs-target="add_modal" class="btn btn-primary">
          <span class="svg-icon svg-icon-1"><i class="fa fa-plus"></i> </span>Yeni</a>
      </div>
    </div>
    <div class="card-body pt-0 pb-5">
      <veri-tablo :datas="datas" :headers="tableHeader" :links="links" :metas="metas">
        <template v-slot:cell-title="{ row: item }">
          {{ item.title }}
        </template>
        <template v-slot:cell-active="{ row: item }">
          <span v-if="!item.active" class="pulse-ring ms-n1"></span>
          <el-switch
              v-model="item.active"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="changeStatus(item)"
          />
        </template>
        <template v-slot:cell-actions="{row:item}">
          <el-dropdown trigger="click">
                        <span class="el-dropdown-link rounded p-3 bg-light">
                            İşlemler<el-icon class="el-icon--right"><arrow-down /></el-icon>
                        </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="city = item">
                  <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#update_modal" style="color:var(--el-text-color-regular);"> Güncelle </a>
                </el-dropdown-item>
                <el-dropdown-item @click="deleteItem(item)" >Sil</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </veri-tablo>
    </div>
  </div>
  <empty-modal modalId="add_modal" ref="add_modal__ref" modalTitle="Şehir Ekle">
    <template v-slot:ModalBody>
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-md-12">
              <div class="input-group mb-5">
                <label for="name" class="form-label">Şehir Adı</label>
                <input v-model="city.title" type="text" class="form-control w-100" id="name" placeholder="Şehir Adı"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:ModalFooter>
      <a @click="save()" class="btn btn-primary">Kaydet</a>
    </template>
  </empty-modal>
  <empty-modal modalId="update_modal" ref="update_modal__ref" modalTitle="Şehir Güncelle">
    <template v-slot:ModalBody>
      <div class="row">
        <div class="col-md-9">
          <div class="input-group mb-5">
            <label for="" class="form-label">Şehir Adı </label>
            <input v-model="city.title" type="text" class="form-control w-100" placeholder="Şehir Adı"/>
          </div>
        </div>
        <div class="col-md-3">
          <label for="" class="form-label">Durum</label>
          <el-switch
              v-model="city.active"
              active-color="#13ce66"
              inactive-color="#ff4949"
          />
        </div>
      </div>
    </template>
    <template v-slot:ModalFooter>
      <a @click="update()" class="btn btn-primary">Kaydet</a>
      <button @click="update_modal__ref.close()" class="btn btn-danger">Kapat</button>
    </template>
  </empty-modal>

</template>
<script>

import { useStore } from "vuex";
import { ArrowDown, Plus } from '@element-plus/icons-vue';
import { computed, onMounted ,reactive, ref } from "vue";
import store from '@/store';
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import VeriTablo from '@/components/tablo/VeriTablo.vue'
import EmptyModal from '@/components/modals/forms/EmptyModal.vue';
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { inject } from 'vue';


import  useCruds  from '@/composables/useCrud.js';


export default {
  components: {
    VeriTablo,
    EmptyModal,
    ArrowDown
  },
  setup() {
    const city = ref({cityId:0,title:'',active:''});
    const payload = reactive( {slug: "/cities",data: city})

    const add_modal__ref = ref();
    const update_modal__ref = ref();

    const { saveCrud, updateCrud, deleteCrud,loadCrud } = useCruds();

    const tableHeader = [
      {
        name: "Şehir",
        key:'title'
      },
      {
        name: "Durum",
        key: "active",
      },
      {
        name: "İşlemler",
        key: "actions",
      },
    ];

    const store = useStore();
    const router = useRouter();
    const datas = ref({});
    const metas = ref({});
    const links = ref({});


    const save = () => {
      saveCrud(payload).then(
          function(value) {
            datas.value.unshift(value.data);
            add_modal__ref.value.close();
          }
      );
    };

    const update = () => {
      payload.slug = "/cities/"+city.value.cityId;
      updateCrud(payload).then(
          function(value) {
            update_modal__ref.value.close();
          }
      );
    };
    const emitter = inject('emitter');

    const deleteItem = (item) => {
      payload.slug = "/cities/"+item.cityId;
      deleteCrud(payload).then(function(value){
        if(value){
          for (let i = 0; i < datas.value.length; i++) {
            if (datas.value[i] === item) {
              datas.value.splice(i, 1);
            }
          }
        }
      });

    };

    onMounted(() => {
      loadCrud('/cities').then(function(response){
        if(response.success){

          datas.value = response.data;
          links.value = response.links;
          metas.value = response.meta;
        }
      });
      emitter.on('PageButtonClicked', (value) => {
        if(router.currentRoute.value.path == "/cities"){
          loadCrud('/cities',value).then(function(response){
            if(response.success){
              datas.value = response.data;
              links.value = response.links;
              metas.value = response.meta;
            }
          });
        }
      });


    });

    return {
      tableHeader,
      datas,
      city,
      deleteItem,
      save,
      update,
      links,
      metas,
      add_modal__ref,
      update_modal__ref
    }
  }
}


</script>
<style lang="css">
.colorful-div{
  width:100px;
  height:24px;
  border-radius:8px;
  padding:5px;
  background:#fff;
  font-family: 'Comfortaa', cursive;
  cursor:pointer;
  color:#fff;
  margin:auto;
}

.badge{
  width:24px;
  height:24px;
  display:flex;
  justify-content:center;
  align-items:center;
  align-content:center;
}

.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

</style>


<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>